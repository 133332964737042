import _jquery from "jquery";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/*! tooltipster v4.2.8 */
!function (a, b) {
  exports = b(_jquery);
}(exports, function (a) {
  function b(a) {
    (this || _global).$container, (this || _global).constraints = null, (this || _global).__$tooltip, this.__init(a);
  }

  function c(b, c) {
    var d = !0;
    return a.each(b, function (a, e) {
      return void 0 === c[a] || b[a] !== c[a] ? (d = !1, !1) : void 0;
    }), d;
  }

  function d(b) {
    var c = b.attr("id"),
        d = c ? h.window.document.getElementById(c) : null;
    return d ? d === b[0] : a.contains(h.window.document.body, b[0]);
  }

  function e() {
    if (!g) return !1;
    var a = g.document.body || g.document.documentElement,
        b = a.style,
        c = "transition",
        d = ["Moz", "Webkit", "Khtml", "O", "ms"];
    if ("string" == typeof b[c]) return !0;
    c = c.charAt(0).toUpperCase() + c.substr(1);

    for (var e = 0; e < d.length; e++) if ("string" == typeof b[d[e] + c]) return !0;

    return !1;
  }

  var f = {
    animation: "fade",
    animationDuration: 350,
    content: null,
    contentAsHTML: !1,
    contentCloning: !1,
    debug: !0,
    delay: 300,
    delayTouch: [300, 500],
    functionInit: null,
    functionBefore: null,
    functionReady: null,
    functionAfter: null,
    functionFormat: null,
    IEmin: 6,
    interactive: !1,
    multiple: !1,
    parent: null,
    plugins: ["sideTip"],
    repositionOnScroll: !1,
    restoration: "none",
    selfDestruction: !0,
    theme: [],
    timer: 0,
    trackerInterval: 500,
    trackOrigin: !1,
    trackTooltip: !1,
    trigger: "hover",
    triggerClose: {
      click: !1,
      mouseleave: !1,
      originClick: !1,
      scroll: !1,
      tap: !1,
      touchleave: !1
    },
    triggerOpen: {
      click: !1,
      mouseenter: !1,
      tap: !1,
      touchstart: !1
    },
    updateAnimation: "rotate",
    zIndex: 9999999
  },
      g = "undefined" != typeof window ? window : null,
      h = {
    hasTouchCapability: !(!g || !("ontouchstart" in g || g.DocumentTouch && g.document instanceof g.DocumentTouch || g.navigator.maxTouchPoints)),
    hasTransitions: e(),
    IE: !1,
    semVer: "4.2.8",
    window: g
  },
      i = function () {
    (this || _global).__$emitterPrivate = a({}), (this || _global).__$emitterPublic = a({}), (this || _global).__instancesLatestArr = [], (this || _global).__plugins = {}, (this || _global)._env = h;
  };

  i.prototype = {
    __bridge: function (b, c, d) {
      if (!c[d]) {
        var e = function () {};

        e.prototype = b;
        var g = new e();
        g.__init && g.__init(c), a.each(b, function (a, b) {
          0 != a.indexOf("__") && (c[a] ? f.debug && console.log("The " + a + " method of the " + d + " plugin conflicts with another plugin or native methods") : (c[a] = function () {
            return g[a].apply(g, Array.prototype.slice.apply(arguments));
          }, c[a].bridged = g));
        }), c[d] = g;
      }

      return this || _global;
    },
    __setWindow: function (a) {
      return h.window = a, this || _global;
    },
    _getRuler: function (a) {
      return new b(a);
    },
    _off: function () {
      return (this || _global).__$emitterPrivate.off.apply((this || _global).__$emitterPrivate, Array.prototype.slice.apply(arguments)), this || _global;
    },
    _on: function () {
      return (this || _global).__$emitterPrivate.on.apply((this || _global).__$emitterPrivate, Array.prototype.slice.apply(arguments)), this || _global;
    },
    _one: function () {
      return (this || _global).__$emitterPrivate.one.apply((this || _global).__$emitterPrivate, Array.prototype.slice.apply(arguments)), this || _global;
    },
    _plugin: function (b) {
      var c = this || _global;

      if ("string" == typeof b) {
        var d = b,
            e = null;
        return d.indexOf(".") > 0 ? e = c.__plugins[d] : a.each(c.__plugins, function (a, b) {
          return b.name.substring(b.name.length - d.length - 1) == "." + d ? (e = b, !1) : void 0;
        }), e;
      }

      if (b.name.indexOf(".") < 0) throw new Error("Plugins must be namespaced");
      return c.__plugins[b.name] = b, b.core && c.__bridge(b.core, c, b.name), this || _global;
    },
    _trigger: function () {
      var a = Array.prototype.slice.apply(arguments);
      return "string" == typeof a[0] && (a[0] = {
        type: a[0]
      }), (this || _global).__$emitterPrivate.trigger.apply((this || _global).__$emitterPrivate, a), (this || _global).__$emitterPublic.trigger.apply((this || _global).__$emitterPublic, a), this || _global;
    },
    instances: function (b) {
      var c = [],
          d = b || ".tooltipstered";
      return a(d).each(function () {
        var b = a(this || _global),
            d = b.data("tooltipster-ns");
        d && a.each(d, function (a, d) {
          c.push(b.data(d));
        });
      }), c;
    },
    instancesLatest: function () {
      return (this || _global).__instancesLatestArr;
    },
    off: function () {
      return (this || _global).__$emitterPublic.off.apply((this || _global).__$emitterPublic, Array.prototype.slice.apply(arguments)), this || _global;
    },
    on: function () {
      return (this || _global).__$emitterPublic.on.apply((this || _global).__$emitterPublic, Array.prototype.slice.apply(arguments)), this || _global;
    },
    one: function () {
      return (this || _global).__$emitterPublic.one.apply((this || _global).__$emitterPublic, Array.prototype.slice.apply(arguments)), this || _global;
    },
    origins: function (b) {
      var c = b ? b + " " : "";
      return a(c + ".tooltipstered").toArray();
    },
    setDefaults: function (b) {
      return a.extend(f, b), this || _global;
    },
    triggerHandler: function () {
      return (this || _global).__$emitterPublic.triggerHandler.apply((this || _global).__$emitterPublic, Array.prototype.slice.apply(arguments)), this || _global;
    }
  }, a.tooltipster = new i(), a.Tooltipster = function (b, c) {
    (this || _global).__callbacks = {
      close: [],
      open: []
    }, (this || _global).__closingTime, (this || _global).__Content, (this || _global).__contentBcr, (this || _global).__destroyed = !1, (this || _global).__$emitterPrivate = a({}), (this || _global).__$emitterPublic = a({}), (this || _global).__enabled = !0, (this || _global).__garbageCollector, (this || _global).__Geometry, (this || _global).__lastPosition, (this || _global).__namespace = "tooltipster-" + Math.round(1000000 * Math.random()), (this || _global).__options, (this || _global).__$originParents, (this || _global).__pointerIsOverOrigin = !1, (this || _global).__previousThemes = [], (this || _global).__state = "closed", (this || _global).__timeouts = {
      close: [],
      open: null
    }, (this || _global).__touchEvents = [], (this || _global).__tracker = null, (this || _global)._$origin, (this || _global)._$tooltip, this.__init(b, c);
  }, a.Tooltipster.prototype = {
    __init: function (b, c) {
      var d = this || _global;

      if (d._$origin = a(b), d.__options = a.extend(!0, {}, f, c), d.__optionsFormat(), !h.IE || h.IE >= d.__options.IEmin) {
        var e = null;
        if (void 0 === d._$origin.data("tooltipster-initialTitle") && (e = d._$origin.attr("title"), void 0 === e && (e = null), d._$origin.data("tooltipster-initialTitle", e)), null !== d.__options.content) d.__contentSet(d.__options.content);else {
          var g,
              i = d._$origin.attr("data-tooltip-content");

          i && (g = a(i)), g && g[0] ? d.__contentSet(g.first()) : d.__contentSet(e);
        }
        d._$origin.removeAttr("title").addClass("tooltipstered"), d.__prepareOrigin(), d.__prepareGC(), a.each(d.__options.plugins, function (a, b) {
          d._plug(b);
        }), h.hasTouchCapability && a(h.window.document.body).on("touchmove." + d.__namespace + "-triggerOpen", function (a) {
          d._touchRecordEvent(a);
        }), d._on("created", function () {
          d.__prepareTooltip();
        })._on("repositioned", function (a) {
          d.__lastPosition = a.position;
        });
      } else d.__options.disabled = !0;
    },
    __contentInsert: function () {
      var a = this || _global,
          b = a._$tooltip.find(".tooltipster-content"),
          c = a.__Content,
          d = function (a) {
        c = a;
      };

      return a._trigger({
        type: "format",
        content: a.__Content,
        format: d
      }), a.__options.functionFormat && (c = a.__options.functionFormat.call(a, a, {
        origin: a._$origin[0]
      }, a.__Content)), "string" != typeof c || a.__options.contentAsHTML ? b.empty().append(c) : b.text(c), a;
    },
    __contentSet: function (b) {
      return b instanceof a && (this || _global).__options.contentCloning && (b = b.clone(!0)), (this || _global).__Content = b, this._trigger({
        type: "updated",
        content: b
      }), this || _global;
    },
    __destroyError: function () {
      throw new Error("This tooltip has been destroyed and cannot execute your method call.");
    },
    __geometry: function () {
      var b = this || _global,
          c = b._$origin,
          d = b._$origin.is("area");

      if (d) {
        var e = b._$origin.parent().attr("name");

        c = a("img[usemap=\"#" + e + "\"]");
      }

      var f = c[0].getBoundingClientRect(),
          g = a(h.window.document),
          i = a(h.window),
          j = c,
          k = {
        available: {
          document: null,
          window: null
        },
        document: {
          size: {
            height: g.height(),
            width: g.width()
          }
        },
        window: {
          scroll: {
            left: h.window.scrollX || h.window.document.documentElement.scrollLeft,
            top: h.window.scrollY || h.window.document.documentElement.scrollTop
          },
          size: {
            height: i.height(),
            width: i.width()
          }
        },
        origin: {
          fixedLineage: !1,
          offset: {},
          size: {
            height: f.bottom - f.top,
            width: f.right - f.left
          },
          usemapImage: d ? c[0] : null,
          windowOffset: {
            bottom: f.bottom,
            left: f.left,
            right: f.right,
            top: f.top
          }
        }
      };

      if (d) {
        var l = b._$origin.attr("shape"),
            m = b._$origin.attr("coords");

        if (m && (m = m.split(","), a.map(m, function (a, b) {
          m[b] = parseInt(a);
        })), "default" != l) switch (l) {
          case "circle":
            var n = m[0],
                o = m[1],
                p = m[2],
                q = o - p,
                r = n - p;
            k.origin.size.height = 2 * p, k.origin.size.width = k.origin.size.height, k.origin.windowOffset.left += r, k.origin.windowOffset.top += q;
            break;

          case "rect":
            var s = m[0],
                t = m[1],
                u = m[2],
                v = m[3];
            k.origin.size.height = v - t, k.origin.size.width = u - s, k.origin.windowOffset.left += s, k.origin.windowOffset.top += t;
            break;

          case "poly":
            for (var w = 0, x = 0, y = 0, z = 0, A = "even", B = 0; B < m.length; B++) {
              var C = m[B];
              "even" == A ? (C > y && (y = C, 0 === B && (w = y)), w > C && (w = C), A = "odd") : (C > z && (z = C, 1 == B && (x = z)), x > C && (x = C), A = "even");
            }

            k.origin.size.height = z - x, k.origin.size.width = y - w, k.origin.windowOffset.left += w, k.origin.windowOffset.top += x;
        }
      }

      var D = function (a) {
        k.origin.size.height = a.height, k.origin.windowOffset.left = a.left, k.origin.windowOffset.top = a.top, k.origin.size.width = a.width;
      };

      for (b._trigger({
        type: "geometry",
        edit: D,
        geometry: {
          height: k.origin.size.height,
          left: k.origin.windowOffset.left,
          top: k.origin.windowOffset.top,
          width: k.origin.size.width
        }
      }), k.origin.windowOffset.right = k.origin.windowOffset.left + k.origin.size.width, k.origin.windowOffset.bottom = k.origin.windowOffset.top + k.origin.size.height, k.origin.offset.left = k.origin.windowOffset.left + k.window.scroll.left, k.origin.offset.top = k.origin.windowOffset.top + k.window.scroll.top, k.origin.offset.bottom = k.origin.offset.top + k.origin.size.height, k.origin.offset.right = k.origin.offset.left + k.origin.size.width, k.available.document = {
        bottom: {
          height: k.document.size.height - k.origin.offset.bottom,
          width: k.document.size.width
        },
        left: {
          height: k.document.size.height,
          width: k.origin.offset.left
        },
        right: {
          height: k.document.size.height,
          width: k.document.size.width - k.origin.offset.right
        },
        top: {
          height: k.origin.offset.top,
          width: k.document.size.width
        }
      }, k.available.window = {
        bottom: {
          height: Math.max(k.window.size.height - Math.max(k.origin.windowOffset.bottom, 0), 0),
          width: k.window.size.width
        },
        left: {
          height: k.window.size.height,
          width: Math.max(k.origin.windowOffset.left, 0)
        },
        right: {
          height: k.window.size.height,
          width: Math.max(k.window.size.width - Math.max(k.origin.windowOffset.right, 0), 0)
        },
        top: {
          height: Math.max(k.origin.windowOffset.top, 0),
          width: k.window.size.width
        }
      }; "html" != j[0].tagName.toLowerCase();) {
        if ("fixed" == j.css("position")) {
          k.origin.fixedLineage = !0;
          break;
        }

        j = j.parent();
      }

      return k;
    },
    __optionsFormat: function () {
      return "number" == typeof (this || _global).__options.animationDuration && ((this || _global).__options.animationDuration = [(this || _global).__options.animationDuration, (this || _global).__options.animationDuration]), "number" == typeof (this || _global).__options.delay && ((this || _global).__options.delay = [(this || _global).__options.delay, (this || _global).__options.delay]), "number" == typeof (this || _global).__options.delayTouch && ((this || _global).__options.delayTouch = [(this || _global).__options.delayTouch, (this || _global).__options.delayTouch]), "string" == typeof (this || _global).__options.theme && ((this || _global).__options.theme = [(this || _global).__options.theme]), null === (this || _global).__options.parent ? (this || _global).__options.parent = a(h.window.document.body) : "string" == typeof (this || _global).__options.parent && ((this || _global).__options.parent = a((this || _global).__options.parent)), "hover" == (this || _global).__options.trigger ? ((this || _global).__options.triggerOpen = {
        mouseenter: !0,
        touchstart: !0
      }, (this || _global).__options.triggerClose = {
        mouseleave: !0,
        originClick: !0,
        touchleave: !0
      }) : "click" == (this || _global).__options.trigger && ((this || _global).__options.triggerOpen = {
        click: !0,
        tap: !0
      }, (this || _global).__options.triggerClose = {
        click: !0,
        tap: !0
      }), this._trigger("options"), this || _global;
    },
    __prepareGC: function () {
      var b = this || _global;
      return b.__options.selfDestruction ? b.__garbageCollector = setInterval(function () {
        var c = new Date().getTime();
        b.__touchEvents = a.grep(b.__touchEvents, function (a, b) {
          return c - a.time > 60000;
        }), d(b._$origin) || b.close(function () {
          b.destroy();
        });
      }, 20000) : clearInterval(b.__garbageCollector), b;
    },
    __prepareOrigin: function () {
      var a = this || _global;

      if (a._$origin.off("." + a.__namespace + "-triggerOpen"), h.hasTouchCapability && a._$origin.on("touchstart." + a.__namespace + "-triggerOpen touchend." + a.__namespace + "-triggerOpen touchcancel." + a.__namespace + "-triggerOpen", function (b) {
        a._touchRecordEvent(b);
      }), a.__options.triggerOpen.click || a.__options.triggerOpen.tap && h.hasTouchCapability) {
        var b = "";
        a.__options.triggerOpen.click && (b += "click." + a.__namespace + "-triggerOpen "), a.__options.triggerOpen.tap && h.hasTouchCapability && (b += "touchend." + a.__namespace + "-triggerOpen"), a._$origin.on(b, function (b) {
          a._touchIsMeaningfulEvent(b) && a._open(b);
        });
      }

      if (a.__options.triggerOpen.mouseenter || a.__options.triggerOpen.touchstart && h.hasTouchCapability) {
        var b = "";
        a.__options.triggerOpen.mouseenter && (b += "mouseenter." + a.__namespace + "-triggerOpen "), a.__options.triggerOpen.touchstart && h.hasTouchCapability && (b += "touchstart." + a.__namespace + "-triggerOpen"), a._$origin.on(b, function (b) {
          !a._touchIsTouchEvent(b) && a._touchIsEmulatedEvent(b) || (a.__pointerIsOverOrigin = !0, a._openShortly(b));
        });
      }

      if (a.__options.triggerClose.mouseleave || a.__options.triggerClose.touchleave && h.hasTouchCapability) {
        var b = "";
        a.__options.triggerClose.mouseleave && (b += "mouseleave." + a.__namespace + "-triggerOpen "), a.__options.triggerClose.touchleave && h.hasTouchCapability && (b += "touchend." + a.__namespace + "-triggerOpen touchcancel." + a.__namespace + "-triggerOpen"), a._$origin.on(b, function (b) {
          a._touchIsMeaningfulEvent(b) && (a.__pointerIsOverOrigin = !1);
        });
      }

      return a;
    },
    __prepareTooltip: function () {
      var b = this || _global,
          c = b.__options.interactive ? "auto" : "";
      return b._$tooltip.attr("id", b.__namespace).css({
        "pointer-events": c,
        zIndex: b.__options.zIndex
      }), a.each(b.__previousThemes, function (a, c) {
        b._$tooltip.removeClass(c);
      }), a.each(b.__options.theme, function (a, c) {
        b._$tooltip.addClass(c);
      }), b.__previousThemes = a.merge([], b.__options.theme), b;
    },
    __scrollHandler: function (b) {
      var c = this || _global;
      if (c.__options.triggerClose.scroll) c._close(b);else if (d(c._$origin) && d(c._$tooltip)) {
        var e = null;
        if (b.target === h.window.document) c.__Geometry.origin.fixedLineage || c.__options.repositionOnScroll && c.reposition(b);else {
          e = c.__geometry();
          var f = !1;
          if ("fixed" != c._$origin.css("position") && c.__$originParents.each(function (b, c) {
            var d = a(c),
                g = d.css("overflow-x"),
                h = d.css("overflow-y");

            if ("visible" != g || "visible" != h) {
              var i = c.getBoundingClientRect();
              if ("visible" != g && (e.origin.windowOffset.left < i.left || e.origin.windowOffset.right > i.right)) return f = !0, !1;
              if ("visible" != h && (e.origin.windowOffset.top < i.top || e.origin.windowOffset.bottom > i.bottom)) return f = !0, !1;
            }

            return "fixed" == d.css("position") ? !1 : void 0;
          }), f) c._$tooltip.css("visibility", "hidden");else if (c._$tooltip.css("visibility", "visible"), c.__options.repositionOnScroll) c.reposition(b);else {
            var g = e.origin.offset.left - c.__Geometry.origin.offset.left,
                i = e.origin.offset.top - c.__Geometry.origin.offset.top;

            c._$tooltip.css({
              left: c.__lastPosition.coord.left + g,
              top: c.__lastPosition.coord.top + i
            });
          }
        }

        c._trigger({
          type: "scroll",
          event: b,
          geo: e
        });
      }
      return c;
    },
    __stateSet: function (a) {
      return (this || _global).__state = a, this._trigger({
        type: "state",
        state: a
      }), this || _global;
    },
    __timeoutsClear: function () {
      return clearTimeout((this || _global).__timeouts.open), (this || _global).__timeouts.open = null, a.each((this || _global).__timeouts.close, function (a, b) {
        clearTimeout(b);
      }), (this || _global).__timeouts.close = [], this || _global;
    },
    __trackerStart: function () {
      var a = this || _global,
          b = a._$tooltip.find(".tooltipster-content");

      return a.__options.trackTooltip && (a.__contentBcr = b[0].getBoundingClientRect()), a.__tracker = setInterval(function () {
        if (d(a._$origin) && d(a._$tooltip)) {
          if (a.__options.trackOrigin) {
            var e = a.__geometry(),
                f = !1;

            c(e.origin.size, a.__Geometry.origin.size) && (a.__Geometry.origin.fixedLineage ? c(e.origin.windowOffset, a.__Geometry.origin.windowOffset) && (f = !0) : c(e.origin.offset, a.__Geometry.origin.offset) && (f = !0)), f || (a.__options.triggerClose.mouseleave ? a._close() : a.reposition());
          }

          if (a.__options.trackTooltip) {
            var g = b[0].getBoundingClientRect();
            g.height === a.__contentBcr.height && g.width === a.__contentBcr.width || (a.reposition(), a.__contentBcr = g);
          }
        } else a._close();
      }, a.__options.trackerInterval), a;
    },
    _close: function (b, c, d) {
      var e = this || _global,
          f = !0;

      if (e._trigger({
        type: "close",
        event: b,
        stop: function () {
          f = !1;
        }
      }), f || d) {
        c && e.__callbacks.close.push(c), e.__callbacks.open = [], e.__timeoutsClear();

        var g = function () {
          a.each(e.__callbacks.close, function (a, c) {
            c.call(e, e, {
              event: b,
              origin: e._$origin[0]
            });
          }), e.__callbacks.close = [];
        };

        if ("closed" != e.__state) {
          var i = !0,
              j = new Date(),
              k = j.getTime(),
              l = k + e.__options.animationDuration[1];

          if ("disappearing" == e.__state && l > e.__closingTime && e.__options.animationDuration[1] > 0 && (i = !1), i) {
            e.__closingTime = l, "disappearing" != e.__state && e.__stateSet("disappearing");

            var m = function () {
              clearInterval(e.__tracker), e._trigger({
                type: "closing",
                event: b
              }), e._$tooltip.off("." + e.__namespace + "-triggerClose").removeClass("tooltipster-dying"), a(h.window).off("." + e.__namespace + "-triggerClose"), e.__$originParents.each(function (b, c) {
                a(c).off("scroll." + e.__namespace + "-triggerClose");
              }), e.__$originParents = null, a(h.window.document.body).off("." + e.__namespace + "-triggerClose"), e._$origin.off("." + e.__namespace + "-triggerClose"), e._off("dismissable"), e.__stateSet("closed"), e._trigger({
                type: "after",
                event: b
              }), e.__options.functionAfter && e.__options.functionAfter.call(e, e, {
                event: b,
                origin: e._$origin[0]
              }), g();
            };

            h.hasTransitions ? (e._$tooltip.css({
              "-moz-animation-duration": e.__options.animationDuration[1] + "ms",
              "-ms-animation-duration": e.__options.animationDuration[1] + "ms",
              "-o-animation-duration": e.__options.animationDuration[1] + "ms",
              "-webkit-animation-duration": e.__options.animationDuration[1] + "ms",
              "animation-duration": e.__options.animationDuration[1] + "ms",
              "transition-duration": e.__options.animationDuration[1] + "ms"
            }), e._$tooltip.clearQueue().removeClass("tooltipster-show").addClass("tooltipster-dying"), e.__options.animationDuration[1] > 0 && e._$tooltip.delay(e.__options.animationDuration[1]), e._$tooltip.queue(m)) : e._$tooltip.stop().fadeOut(e.__options.animationDuration[1], m);
          }
        } else g();
      }

      return e;
    },
    _off: function () {
      return (this || _global).__$emitterPrivate.off.apply((this || _global).__$emitterPrivate, Array.prototype.slice.apply(arguments)), this || _global;
    },
    _on: function () {
      return (this || _global).__$emitterPrivate.on.apply((this || _global).__$emitterPrivate, Array.prototype.slice.apply(arguments)), this || _global;
    },
    _one: function () {
      return (this || _global).__$emitterPrivate.one.apply((this || _global).__$emitterPrivate, Array.prototype.slice.apply(arguments)), this || _global;
    },
    _open: function (b, c) {
      var e = this || _global;

      if (!e.__destroying && d(e._$origin) && e.__enabled) {
        var f = !0;

        if ("closed" == e.__state && (e._trigger({
          type: "before",
          event: b,
          stop: function () {
            f = !1;
          }
        }), f && e.__options.functionBefore && (f = e.__options.functionBefore.call(e, e, {
          event: b,
          origin: e._$origin[0]
        }))), f !== !1 && null !== e.__Content) {
          c && e.__callbacks.open.push(c), e.__callbacks.close = [], e.__timeoutsClear();

          var g,
              i = function () {
            "stable" != e.__state && e.__stateSet("stable"), a.each(e.__callbacks.open, function (a, b) {
              b.call(e, e, {
                origin: e._$origin[0],
                tooltip: e._$tooltip[0]
              });
            }), e.__callbacks.open = [];
          };

          if ("closed" !== e.__state) g = 0, "disappearing" === e.__state ? (e.__stateSet("appearing"), h.hasTransitions ? (e._$tooltip.clearQueue().removeClass("tooltipster-dying").addClass("tooltipster-show"), e.__options.animationDuration[0] > 0 && e._$tooltip.delay(e.__options.animationDuration[0]), e._$tooltip.queue(i)) : e._$tooltip.stop().fadeIn(i)) : "stable" == e.__state && i();else {
            if (e.__stateSet("appearing"), g = e.__options.animationDuration[0], e.__contentInsert(), e.reposition(b, !0), h.hasTransitions ? (e._$tooltip.addClass("tooltipster-" + e.__options.animation).addClass("tooltipster-initial").css({
              "-moz-animation-duration": e.__options.animationDuration[0] + "ms",
              "-ms-animation-duration": e.__options.animationDuration[0] + "ms",
              "-o-animation-duration": e.__options.animationDuration[0] + "ms",
              "-webkit-animation-duration": e.__options.animationDuration[0] + "ms",
              "animation-duration": e.__options.animationDuration[0] + "ms",
              "transition-duration": e.__options.animationDuration[0] + "ms"
            }), setTimeout(function () {
              "closed" != e.__state && (e._$tooltip.addClass("tooltipster-show").removeClass("tooltipster-initial"), e.__options.animationDuration[0] > 0 && e._$tooltip.delay(e.__options.animationDuration[0]), e._$tooltip.queue(i));
            }, 0)) : e._$tooltip.css("display", "none").fadeIn(e.__options.animationDuration[0], i), e.__trackerStart(), a(h.window).on("resize." + e.__namespace + "-triggerClose", function (b) {
              var c = a(document.activeElement);
              (c.is("input") || c.is("textarea")) && a.contains(e._$tooltip[0], c[0]) || e.reposition(b);
            }).on("scroll." + e.__namespace + "-triggerClose", function (a) {
              e.__scrollHandler(a);
            }), e.__$originParents = e._$origin.parents(), e.__$originParents.each(function (b, c) {
              a(c).on("scroll." + e.__namespace + "-triggerClose", function (a) {
                e.__scrollHandler(a);
              });
            }), e.__options.triggerClose.mouseleave || e.__options.triggerClose.touchleave && h.hasTouchCapability) {
              e._on("dismissable", function (a) {
                a.dismissable ? a.delay ? (m = setTimeout(function () {
                  e._close(a.event);
                }, a.delay), e.__timeouts.close.push(m)) : e._close(a) : clearTimeout(m);
              });

              var j = e._$origin,
                  k = "",
                  l = "",
                  m = null;
              e.__options.interactive && (j = j.add(e._$tooltip)), e.__options.triggerClose.mouseleave && (k += "mouseenter." + e.__namespace + "-triggerClose ", l += "mouseleave." + e.__namespace + "-triggerClose "), e.__options.triggerClose.touchleave && h.hasTouchCapability && (k += "touchstart." + e.__namespace + "-triggerClose", l += "touchend." + e.__namespace + "-triggerClose touchcancel." + e.__namespace + "-triggerClose"), j.on(l, function (a) {
                if (e._touchIsTouchEvent(a) || !e._touchIsEmulatedEvent(a)) {
                  var b = "mouseleave" == a.type ? e.__options.delay : e.__options.delayTouch;

                  e._trigger({
                    delay: b[1],
                    dismissable: !0,
                    event: a,
                    type: "dismissable"
                  });
                }
              }).on(k, function (a) {
                !e._touchIsTouchEvent(a) && e._touchIsEmulatedEvent(a) || e._trigger({
                  dismissable: !1,
                  event: a,
                  type: "dismissable"
                });
              });
            }

            e.__options.triggerClose.originClick && e._$origin.on("click." + e.__namespace + "-triggerClose", function (a) {
              e._touchIsTouchEvent(a) || e._touchIsEmulatedEvent(a) || e._close(a);
            }), (e.__options.triggerClose.click || e.__options.triggerClose.tap && h.hasTouchCapability) && setTimeout(function () {
              if ("closed" != e.__state) {
                var b = "",
                    c = a(h.window.document.body);
                e.__options.triggerClose.click && (b += "click." + e.__namespace + "-triggerClose "), e.__options.triggerClose.tap && h.hasTouchCapability && (b += "touchend." + e.__namespace + "-triggerClose"), c.on(b, function (b) {
                  e._touchIsMeaningfulEvent(b) && (e._touchRecordEvent(b), e.__options.interactive && a.contains(e._$tooltip[0], b.target) || e._close(b));
                }), e.__options.triggerClose.tap && h.hasTouchCapability && c.on("touchstart." + e.__namespace + "-triggerClose", function (a) {
                  e._touchRecordEvent(a);
                });
              }
            }, 0), e._trigger("ready"), e.__options.functionReady && e.__options.functionReady.call(e, e, {
              origin: e._$origin[0],
              tooltip: e._$tooltip[0]
            });
          }

          if (e.__options.timer > 0) {
            var m = setTimeout(function () {
              e._close();
            }, e.__options.timer + g);

            e.__timeouts.close.push(m);
          }
        }
      }

      return e;
    },
    _openShortly: function (a) {
      var b = this || _global,
          c = !0;

      if ("stable" != b.__state && "appearing" != b.__state && !b.__timeouts.open && (b._trigger({
        type: "start",
        event: a,
        stop: function () {
          c = !1;
        }
      }), c)) {
        var d = 0 == a.type.indexOf("touch") ? b.__options.delayTouch : b.__options.delay;
        d[0] ? b.__timeouts.open = setTimeout(function () {
          b.__timeouts.open = null, b.__pointerIsOverOrigin && b._touchIsMeaningfulEvent(a) ? (b._trigger("startend"), b._open(a)) : b._trigger("startcancel");
        }, d[0]) : (b._trigger("startend"), b._open(a));
      }

      return b;
    },
    _optionsExtract: function (b, c) {
      var d = this || _global,
          e = a.extend(!0, {}, c),
          f = d.__options[b];
      return f || (f = {}, a.each(c, function (a, b) {
        var c = d.__options[a];
        void 0 !== c && (f[a] = c);
      })), a.each(e, function (b, c) {
        void 0 !== f[b] && ("object" != typeof c || c instanceof Array || null == c || "object" != typeof f[b] || f[b] instanceof Array || null == f[b] ? e[b] = f[b] : a.extend(e[b], f[b]));
      }), e;
    },
    _plug: function (b) {
      var c = a.tooltipster._plugin(b);

      if (!c) throw new Error("The \"" + b + "\" plugin is not defined");
      return c.instance && a.tooltipster.__bridge(c.instance, this || _global, c.name), this || _global;
    },
    _touchIsEmulatedEvent: function (a) {
      for (var b = !1, c = new Date().getTime(), d = (this || _global).__touchEvents.length - 1; d >= 0; d--) {
        var e = (this || _global).__touchEvents[d];
        if (!(c - e.time < 500)) break;
        e.target === a.target && (b = !0);
      }

      return b;
    },
    _touchIsMeaningfulEvent: function (a) {
      return this._touchIsTouchEvent(a) && !this._touchSwiped(a.target) || !this._touchIsTouchEvent(a) && !this._touchIsEmulatedEvent(a);
    },
    _touchIsTouchEvent: function (a) {
      return 0 == a.type.indexOf("touch");
    },
    _touchRecordEvent: function (a) {
      return this._touchIsTouchEvent(a) && (a.time = new Date().getTime(), (this || _global).__touchEvents.push(a)), this || _global;
    },
    _touchSwiped: function (a) {
      for (var b = !1, c = (this || _global).__touchEvents.length - 1; c >= 0; c--) {
        var d = (this || _global).__touchEvents[c];

        if ("touchmove" == d.type) {
          b = !0;
          break;
        }

        if ("touchstart" == d.type && a === d.target) break;
      }

      return b;
    },
    _trigger: function () {
      var b = Array.prototype.slice.apply(arguments);
      return "string" == typeof b[0] && (b[0] = {
        type: b[0]
      }), b[0].instance = this || _global, b[0].origin = (this || _global)._$origin ? (this || _global)._$origin[0] : null, b[0].tooltip = (this || _global)._$tooltip ? (this || _global)._$tooltip[0] : null, (this || _global).__$emitterPrivate.trigger.apply((this || _global).__$emitterPrivate, b), a.tooltipster._trigger.apply(a.tooltipster, b), (this || _global).__$emitterPublic.trigger.apply((this || _global).__$emitterPublic, b), this || _global;
    },
    _unplug: function (b) {
      var c = this || _global;

      if (c[b]) {
        var d = a.tooltipster._plugin(b);

        d.instance && a.each(d.instance, function (a, d) {
          c[a] && c[a].bridged === c[b] && delete c[a];
        }), c[b].__destroy && c[b].__destroy(), delete c[b];
      }

      return c;
    },
    close: function (a) {
      return (this || _global).__destroyed ? this.__destroyError() : this._close(null, a), this || _global;
    },
    content: function (a) {
      var b = this || _global;
      if (void 0 === a) return b.__Content;
      if (b.__destroyed) b.__destroyError();else if (b.__contentSet(a), null !== b.__Content) {
        if ("closed" !== b.__state && (b.__contentInsert(), b.reposition(), b.__options.updateAnimation)) if (h.hasTransitions) {
          var c = b.__options.updateAnimation;
          b._$tooltip.addClass("tooltipster-update-" + c), setTimeout(function () {
            "closed" != b.__state && b._$tooltip.removeClass("tooltipster-update-" + c);
          }, 1000);
        } else b._$tooltip.fadeTo(200, 0.5, function () {
          "closed" != b.__state && b._$tooltip.fadeTo(200, 1);
        });
      } else b._close();
      return b;
    },
    destroy: function () {
      var b = this || _global;
      if (b.__destroyed) b.__destroyError();else {
        "closed" != b.__state ? b.option("animationDuration", 0)._close(null, null, !0) : b.__timeoutsClear(), b._trigger("destroy"), b.__destroyed = !0, b._$origin.removeData(b.__namespace).off("." + b.__namespace + "-triggerOpen"), a(h.window.document.body).off("." + b.__namespace + "-triggerOpen");

        var c = b._$origin.data("tooltipster-ns");

        if (c) if (1 === c.length) {
          var d = null;
          "previous" == b.__options.restoration ? d = b._$origin.data("tooltipster-initialTitle") : "current" == b.__options.restoration && (d = "string" == typeof b.__Content ? b.__Content : a("<div></div>").append(b.__Content).html()), d && b._$origin.attr("title", d), b._$origin.removeClass("tooltipstered"), b._$origin.removeData("tooltipster-ns").removeData("tooltipster-initialTitle");
        } else c = a.grep(c, function (a, c) {
          return a !== b.__namespace;
        }), b._$origin.data("tooltipster-ns", c);
        b._trigger("destroyed"), b._off(), b.off(), b.__Content = null, b.__$emitterPrivate = null, b.__$emitterPublic = null, b.__options.parent = null, b._$origin = null, b._$tooltip = null, a.tooltipster.__instancesLatestArr = a.grep(a.tooltipster.__instancesLatestArr, function (a, c) {
          return b !== a;
        }), clearInterval(b.__garbageCollector);
      }
      return b;
    },
    disable: function () {
      return (this || _global).__destroyed ? (this.__destroyError(), this || _global) : (this._close(), (this || _global).__enabled = !1, this || _global);
    },
    elementOrigin: function () {
      return (this || _global).__destroyed ? void this.__destroyError() : (this || _global)._$origin[0];
    },
    elementTooltip: function () {
      return (this || _global)._$tooltip ? (this || _global)._$tooltip[0] : null;
    },
    enable: function () {
      return (this || _global).__enabled = !0, this || _global;
    },
    hide: function (a) {
      return this.close(a);
    },
    instance: function () {
      return this || _global;
    },
    off: function () {
      return (this || _global).__destroyed || (this || _global).__$emitterPublic.off.apply((this || _global).__$emitterPublic, Array.prototype.slice.apply(arguments)), this || _global;
    },
    on: function () {
      return (this || _global).__destroyed ? this.__destroyError() : (this || _global).__$emitterPublic.on.apply((this || _global).__$emitterPublic, Array.prototype.slice.apply(arguments)), this || _global;
    },
    one: function () {
      return (this || _global).__destroyed ? this.__destroyError() : (this || _global).__$emitterPublic.one.apply((this || _global).__$emitterPublic, Array.prototype.slice.apply(arguments)), this || _global;
    },
    open: function (a) {
      return (this || _global).__destroyed ? this.__destroyError() : this._open(null, a), this || _global;
    },
    option: function (b, c) {
      return void 0 === c ? (this || _global).__options[b] : ((this || _global).__destroyed ? this.__destroyError() : ((this || _global).__options[b] = c, this.__optionsFormat(), a.inArray(b, ["trigger", "triggerClose", "triggerOpen"]) >= 0 && this.__prepareOrigin(), "selfDestruction" === b && this.__prepareGC()), this || _global);
    },
    reposition: function (a, b) {
      var c = this || _global;
      return c.__destroyed ? c.__destroyError() : "closed" != c.__state && d(c._$origin) && (b || d(c._$tooltip)) && (b || c._$tooltip.detach(), c.__Geometry = c.__geometry(), c._trigger({
        type: "reposition",
        event: a,
        helper: {
          geo: c.__Geometry
        }
      })), c;
    },
    show: function (a) {
      return this.open(a);
    },
    status: function () {
      return {
        destroyed: (this || _global).__destroyed,
        enabled: (this || _global).__enabled,
        open: "closed" !== (this || _global).__state,
        state: (this || _global).__state
      };
    },
    triggerHandler: function () {
      return (this || _global).__destroyed ? this.__destroyError() : (this || _global).__$emitterPublic.triggerHandler.apply((this || _global).__$emitterPublic, Array.prototype.slice.apply(arguments)), this || _global;
    }
  }, a.fn.tooltipster = function () {
    var b = Array.prototype.slice.apply(arguments),
        c = "You are using a single HTML element as content for several tooltips. You probably want to set the contentCloning option to TRUE.";
    if (0 === (this || _global).length) return this || _global;

    if ("string" == typeof b[0]) {
      var d = "#*$~&";
      return this.each(function () {
        var e = a(this || _global).data("tooltipster-ns"),
            f = e ? a(this || _global).data(e[0]) : null;
        if (!f) throw new Error("You called Tooltipster's \"" + b[0] + "\" method on an uninitialized element");
        if ("function" != typeof f[b[0]]) throw new Error("Unknown method \"" + b[0] + "\"");
        (this || _global).length > 1 && "content" == b[0] && (b[1] instanceof a || "object" == typeof b[1] && null != b[1] && b[1].tagName) && !f.__options.contentCloning && f.__options.debug && console.log(c);
        var g = f[b[0]](b[1], b[2]);
        return g !== f || "instance" === b[0] ? (d = g, !1) : void 0;
      }), "#*$~&" !== d ? d : this || _global;
    }

    a.tooltipster.__instancesLatestArr = [];
    var e = b[0] && void 0 !== b[0].multiple,
        g = e && b[0].multiple || !e && f.multiple,
        h = b[0] && void 0 !== b[0].content,
        i = h && b[0].content || !h && f.content,
        j = b[0] && void 0 !== b[0].contentCloning,
        k = j && b[0].contentCloning || !j && f.contentCloning,
        l = b[0] && void 0 !== b[0].debug,
        m = l && b[0].debug || !l && f.debug;
    return (this || _global).length > 1 && (i instanceof a || "object" == typeof i && null != i && i.tagName) && !k && m && console.log(c), this.each(function () {
      var c = !1,
          d = a(this || _global),
          e = d.data("tooltipster-ns"),
          f = null;
      e ? g ? c = !0 : m && (console.log("Tooltipster: one or more tooltips are already attached to the element below. Ignoring."), console.log(this || _global)) : c = !0, c && (f = new a.Tooltipster(this || _global, b[0]), e || (e = []), e.push(f.__namespace), d.data("tooltipster-ns", e), d.data(f.__namespace, f), f.__options.functionInit && f.__options.functionInit.call(f, f, {
        origin: this || _global
      }), f._trigger("init")), a.tooltipster.__instancesLatestArr.push(f);
    }), this || _global;
  }, b.prototype = {
    __init: function (b) {
      (this || _global).__$tooltip = b, (this || _global).__$tooltip.css({
        left: 0,
        overflow: "hidden",
        position: "absolute",
        top: 0
      }).find(".tooltipster-content").css("overflow", "auto"), (this || _global).$container = a("<div class=\"tooltipster-ruler\"></div>").append((this || _global).__$tooltip).appendTo(h.window.document.body);
    },
    __forceRedraw: function () {
      var a = (this || _global).__$tooltip.parent();

      (this || _global).__$tooltip.detach(), (this || _global).__$tooltip.appendTo(a);
    },
    constrain: function (a, b) {
      return (this || _global).constraints = {
        width: a,
        height: b
      }, (this || _global).__$tooltip.css({
        display: "block",
        height: "",
        overflow: "auto",
        width: a
      }), this || _global;
    },
    destroy: function () {
      (this || _global).__$tooltip.detach().find(".tooltipster-content").css({
        display: "",
        overflow: ""
      }), (this || _global).$container.remove();
    },
    free: function () {
      return (this || _global).constraints = null, (this || _global).__$tooltip.css({
        display: "",
        height: "",
        overflow: "visible",
        width: ""
      }), this || _global;
    },
    measure: function () {
      this.__forceRedraw();

      var a = (this || _global).__$tooltip[0].getBoundingClientRect(),
          b = {
        size: {
          height: a.height || a.bottom - a.top,
          width: a.width || a.right - a.left
        }
      };

      if ((this || _global).constraints) {
        var c = (this || _global).__$tooltip.find(".tooltipster-content"),
            d = (this || _global).__$tooltip.outerHeight(),
            e = c[0].getBoundingClientRect(),
            f = {
          height: d <= (this || _global).constraints.height,
          width: a.width <= (this || _global).constraints.width && e.width >= c[0].scrollWidth - 1
        };

        b.fits = f.height && f.width;
      }

      return h.IE && h.IE <= 11 && b.size.width !== h.window.document.documentElement.clientWidth && (b.size.width = Math.ceil(b.size.width) + 1), b;
    }
  };
  var j = navigator.userAgent.toLowerCase();
  -1 != j.indexOf("msie") ? h.IE = parseInt(j.split("msie")[1]) : -1 !== j.toLowerCase().indexOf("trident") && -1 !== j.indexOf(" rv:11") ? h.IE = 11 : -1 != j.toLowerCase().indexOf("edge/") && (h.IE = parseInt(j.toLowerCase().split("edge/")[1]));
  var k = "tooltipster.sideTip";
  return a.tooltipster._plugin({
    name: k,
    instance: {
      __defaults: function () {
        return {
          arrow: !0,
          distance: 6,
          functionPosition: null,
          maxWidth: null,
          minIntersection: 16,
          minWidth: 0,
          position: null,
          side: "top",
          viewportAware: !0
        };
      },
      __init: function (a) {
        var b = this || _global;
        b.__instance = a, b.__namespace = "tooltipster-sideTip-" + Math.round(1000000 * Math.random()), b.__previousState = "closed", b.__options, b.__optionsFormat(), b.__instance._on("state." + b.__namespace, function (a) {
          "closed" == a.state ? b.__close() : "appearing" == a.state && "closed" == b.__previousState && b.__create(), b.__previousState = a.state;
        }), b.__instance._on("options." + b.__namespace, function () {
          b.__optionsFormat();
        }), b.__instance._on("reposition." + b.__namespace, function (a) {
          b.__reposition(a.event, a.helper);
        });
      },
      __close: function () {
        (this || _global).__instance.content() instanceof a && (this || _global).__instance.content().detach(), (this || _global).__instance._$tooltip.remove(), (this || _global).__instance._$tooltip = null;
      },
      __create: function () {
        var b = a("<div class=\"tooltipster-base tooltipster-sidetip\"><div class=\"tooltipster-box\"><div class=\"tooltipster-content\"></div></div><div class=\"tooltipster-arrow\"><div class=\"tooltipster-arrow-uncropped\"><div class=\"tooltipster-arrow-border\"></div><div class=\"tooltipster-arrow-background\"></div></div></div></div>");
        (this || _global).__options.arrow || b.find(".tooltipster-box").css("margin", 0).end().find(".tooltipster-arrow").hide(), (this || _global).__options.minWidth && b.css("min-width", (this || _global).__options.minWidth + "px"), (this || _global).__options.maxWidth && b.css("max-width", (this || _global).__options.maxWidth + "px"), (this || _global).__instance._$tooltip = b, (this || _global).__instance._trigger("created");
      },
      __destroy: function () {
        (this || _global).__instance._off("." + self.__namespace);
      },
      __optionsFormat: function () {
        var b = this || _global;

        if (b.__options = b.__instance._optionsExtract(k, b.__defaults()), b.__options.position && (b.__options.side = b.__options.position), "object" != typeof b.__options.distance && (b.__options.distance = [b.__options.distance]), b.__options.distance.length < 4 && (void 0 === b.__options.distance[1] && (b.__options.distance[1] = b.__options.distance[0]), void 0 === b.__options.distance[2] && (b.__options.distance[2] = b.__options.distance[0]), void 0 === b.__options.distance[3] && (b.__options.distance[3] = b.__options.distance[1])), b.__options.distance = {
          top: b.__options.distance[0],
          right: b.__options.distance[1],
          bottom: b.__options.distance[2],
          left: b.__options.distance[3]
        }, "string" == typeof b.__options.side) {
          var c = {
            top: "bottom",
            right: "left",
            bottom: "top",
            left: "right"
          };
          b.__options.side = [b.__options.side, c[b.__options.side]], "left" == b.__options.side[0] || "right" == b.__options.side[0] ? b.__options.side.push("top", "bottom") : b.__options.side.push("right", "left");
        }

        6 === a.tooltipster._env.IE && b.__options.arrow !== !0 && (b.__options.arrow = !1);
      },
      __reposition: function (b, c) {
        var d,
            e = this || _global,
            f = e.__targetFind(c),
            g = [];

        e.__instance._$tooltip.detach();

        var h = e.__instance._$tooltip.clone(),
            i = a.tooltipster._getRuler(h),
            j = !1,
            k = e.__instance.option("animation");

        switch (k && h.removeClass("tooltipster-" + k), a.each(["window", "document"], function (d, k) {
          var l = null;
          if (e.__instance._trigger({
            container: k,
            helper: c,
            satisfied: j,
            takeTest: function (a) {
              l = a;
            },
            results: g,
            type: "positionTest"
          }), 1 == l || 0 != l && 0 == j && ("window" != k || e.__options.viewportAware)) for (var d = 0; d < e.__options.side.length; d++) {
            var m = {
              horizontal: 0,
              vertical: 0
            },
                n = e.__options.side[d];
            "top" == n || "bottom" == n ? m.vertical = e.__options.distance[n] : m.horizontal = e.__options.distance[n], e.__sideChange(h, n), a.each(["natural", "constrained"], function (a, d) {
              if (l = null, e.__instance._trigger({
                container: k,
                event: b,
                helper: c,
                mode: d,
                results: g,
                satisfied: j,
                side: n,
                takeTest: function (a) {
                  l = a;
                },
                type: "positionTest"
              }), 1 == l || 0 != l && 0 == j) {
                var h = {
                  container: k,
                  distance: m,
                  fits: null,
                  mode: d,
                  outerSize: null,
                  side: n,
                  size: null,
                  target: f[n],
                  whole: null
                },
                    o = "natural" == d ? i.free() : i.constrain(c.geo.available[k][n].width - m.horizontal, c.geo.available[k][n].height - m.vertical),
                    p = o.measure();
                if (h.size = p.size, h.outerSize = {
                  height: p.size.height + m.vertical,
                  width: p.size.width + m.horizontal
                }, "natural" == d ? c.geo.available[k][n].width >= h.outerSize.width && c.geo.available[k][n].height >= h.outerSize.height ? h.fits = !0 : h.fits = !1 : h.fits = p.fits, "window" == k && (h.fits ? "top" == n || "bottom" == n ? h.whole = c.geo.origin.windowOffset.right >= e.__options.minIntersection && c.geo.window.size.width - c.geo.origin.windowOffset.left >= e.__options.minIntersection : h.whole = c.geo.origin.windowOffset.bottom >= e.__options.minIntersection && c.geo.window.size.height - c.geo.origin.windowOffset.top >= e.__options.minIntersection : h.whole = !1), g.push(h), h.whole) j = !0;else if ("natural" == h.mode && (h.fits || h.size.width <= c.geo.available[k][n].width)) return !1;
              }
            });
          }
        }), e.__instance._trigger({
          edit: function (a) {
            g = a;
          },
          event: b,
          helper: c,
          results: g,
          type: "positionTested"
        }), g.sort(function (a, b) {
          if (a.whole && !b.whole) return -1;
          if (!a.whole && b.whole) return 1;

          if (a.whole && b.whole) {
            var c = e.__options.side.indexOf(a.side),
                d = e.__options.side.indexOf(b.side);

            return d > c ? -1 : c > d ? 1 : "natural" == a.mode ? -1 : 1;
          }

          if (a.fits && !b.fits) return -1;
          if (!a.fits && b.fits) return 1;

          if (a.fits && b.fits) {
            var c = e.__options.side.indexOf(a.side),
                d = e.__options.side.indexOf(b.side);

            return d > c ? -1 : c > d ? 1 : "natural" == a.mode ? -1 : 1;
          }

          return "document" == a.container && "bottom" == a.side && "natural" == a.mode ? -1 : 1;
        }), d = g[0], d.coord = {}, d.side) {
          case "left":
          case "right":
            d.coord.top = Math.floor(d.target - d.size.height / 2);
            break;

          case "bottom":
          case "top":
            d.coord.left = Math.floor(d.target - d.size.width / 2);
        }

        switch (d.side) {
          case "left":
            d.coord.left = c.geo.origin.windowOffset.left - d.outerSize.width;
            break;

          case "right":
            d.coord.left = c.geo.origin.windowOffset.right + d.distance.horizontal;
            break;

          case "top":
            d.coord.top = c.geo.origin.windowOffset.top - d.outerSize.height;
            break;

          case "bottom":
            d.coord.top = c.geo.origin.windowOffset.bottom + d.distance.vertical;
        }

        "window" == d.container ? "top" == d.side || "bottom" == d.side ? d.coord.left < 0 ? c.geo.origin.windowOffset.right - (this || _global).__options.minIntersection >= 0 ? d.coord.left = 0 : d.coord.left = c.geo.origin.windowOffset.right - (this || _global).__options.minIntersection - 1 : d.coord.left > c.geo.window.size.width - d.size.width && (c.geo.origin.windowOffset.left + (this || _global).__options.minIntersection <= c.geo.window.size.width ? d.coord.left = c.geo.window.size.width - d.size.width : d.coord.left = c.geo.origin.windowOffset.left + (this || _global).__options.minIntersection + 1 - d.size.width) : d.coord.top < 0 ? c.geo.origin.windowOffset.bottom - (this || _global).__options.minIntersection >= 0 ? d.coord.top = 0 : d.coord.top = c.geo.origin.windowOffset.bottom - (this || _global).__options.minIntersection - 1 : d.coord.top > c.geo.window.size.height - d.size.height && (c.geo.origin.windowOffset.top + (this || _global).__options.minIntersection <= c.geo.window.size.height ? d.coord.top = c.geo.window.size.height - d.size.height : d.coord.top = c.geo.origin.windowOffset.top + (this || _global).__options.minIntersection + 1 - d.size.height) : (d.coord.left > c.geo.window.size.width - d.size.width && (d.coord.left = c.geo.window.size.width - d.size.width), d.coord.left < 0 && (d.coord.left = 0)), e.__sideChange(h, d.side), c.tooltipClone = h[0], c.tooltipParent = e.__instance.option("parent").parent[0], c.mode = d.mode, c.whole = d.whole, c.origin = e.__instance._$origin[0], c.tooltip = e.__instance._$tooltip[0], delete d.container, delete d.fits, delete d.mode, delete d.outerSize, delete d.whole, d.distance = d.distance.horizontal || d.distance.vertical;
        var l = a.extend(!0, {}, d);

        if (e.__instance._trigger({
          edit: function (a) {
            d = a;
          },
          event: b,
          helper: c,
          position: l,
          type: "position"
        }), e.__options.functionPosition) {
          var m = e.__options.functionPosition.call(e, e.__instance, c, l);

          m && (d = m);
        }

        i.destroy();
        var n, o;
        "top" == d.side || "bottom" == d.side ? (n = {
          prop: "left",
          val: d.target - d.coord.left
        }, o = d.size.width - (this || _global).__options.minIntersection) : (n = {
          prop: "top",
          val: d.target - d.coord.top
        }, o = d.size.height - (this || _global).__options.minIntersection), n.val < (this || _global).__options.minIntersection ? n.val = (this || _global).__options.minIntersection : n.val > o && (n.val = o);
        var p;
        p = c.geo.origin.fixedLineage ? c.geo.origin.windowOffset : {
          left: c.geo.origin.windowOffset.left + c.geo.window.scroll.left,
          top: c.geo.origin.windowOffset.top + c.geo.window.scroll.top
        }, d.coord = {
          left: p.left + (d.coord.left - c.geo.origin.windowOffset.left),
          top: p.top + (d.coord.top - c.geo.origin.windowOffset.top)
        }, e.__sideChange(e.__instance._$tooltip, d.side), c.geo.origin.fixedLineage ? e.__instance._$tooltip.css("position", "fixed") : e.__instance._$tooltip.css("position", ""), e.__instance._$tooltip.css({
          left: d.coord.left,
          top: d.coord.top,
          height: d.size.height,
          width: d.size.width
        }).find(".tooltipster-arrow").css({
          left: "",
          top: ""
        }).css(n.prop, n.val), e.__instance._$tooltip.appendTo(e.__instance.option("parent")), e.__instance._trigger({
          type: "repositioned",
          event: b,
          position: d
        });
      },
      __sideChange: function (a, b) {
        a.removeClass("tooltipster-bottom").removeClass("tooltipster-left").removeClass("tooltipster-right").removeClass("tooltipster-top").addClass("tooltipster-" + b);
      },
      __targetFind: function (a) {
        var b = {},
            c = (this || _global).__instance._$origin[0].getClientRects();

        if (c.length > 1) {
          var d = (this || _global).__instance._$origin.css("opacity");

          1 == d && ((this || _global).__instance._$origin.css("opacity", 0.99), c = (this || _global).__instance._$origin[0].getClientRects(), (this || _global).__instance._$origin.css("opacity", 1));
        }

        if (c.length < 2) b.top = Math.floor(a.geo.origin.windowOffset.left + a.geo.origin.size.width / 2), b.bottom = b.top, b.left = Math.floor(a.geo.origin.windowOffset.top + a.geo.origin.size.height / 2), b.right = b.left;else {
          var e = c[0];
          b.top = Math.floor(e.left + (e.right - e.left) / 2), e = c.length > 2 ? c[Math.ceil(c.length / 2) - 1] : c[0], b.right = Math.floor(e.top + (e.bottom - e.top) / 2), e = c[c.length - 1], b.bottom = Math.floor(e.left + (e.right - e.left) / 2), e = c.length > 2 ? c[Math.ceil((c.length + 1) / 2) - 1] : c[c.length - 1], b.left = Math.floor(e.top + (e.bottom - e.top) / 2);
        }
        return b;
      }
    }
  }), a;
});
export default exports;